//@ts-check
import React from "react"
import { Typography } from "antd"
import { getTheme } from "../theme"

const { Text: AntText } = Typography

export const Text = ({ children, style = {}, ...otherProps }) =>
  !!children && (
    <AntText
      style={{
        color: getTheme().textColor,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </AntText>
  )
