//@ts-check
import "antd/dist/antd.css"
import "./src/variables.css"
import "./src/global.css"

import * as React from 'react'

import { PreviewStoreProvider } from 'gatsby-source-prismic'


export const wrapPageElement = ({ element, props }) => {
  return element
}

export const wrapRootElement = ({ element, props }) => {
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
}

export const onInitialClientRender = ({ }) => {
  setTimeout(function () {
    document.getElementById("___loader").style.display = "none"
  }, 0)
}
