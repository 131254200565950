//@ts-check
import React from "react"
import { Button } from "antd"
import "./style.css"
import { getTheme } from "../../theme"
import { useIsTabletOrMobile } from "../CustomLayout"

export const ButtonPrimary = ({
  href = undefined,
  label,
  style = {},
  ...otherProps
}) => {
  const isMobile = useIsTabletOrMobile()
  return (
    <Button
      style={{
        fontSize: "14px",
        fontFamily: "Signika",
        width: isMobile ? "150px" : "180px",
        // padding: "5px 0px",
        height: 35,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "unset",
        borderRadius: "20px",
        textTransform: "uppercase",
        backgroundColor: getTheme().buttonPrimaryColor,
        borderColor: getTheme().buttonPrimaryColor,
        color: getTheme().onButtonColor,

        ...style,
      }}
      href={href}
      type="primary"
      {...otherProps}
    >
      {label}
    </Button>
  )
}
