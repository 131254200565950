// In src/prismic-configuration.js
module.exports = {
  linkResolver: doc => {
    // URL for a category type
    if (doc.type === "homepage") {
      return `/`
    }
    // URL for a page type
    if (doc.type === "page") {
      return `/${doc.uid}`
    }
    if (doc.type === "subpage") {
      return `/${doc.uid}`
    }
    if (doc.type === "about-us") {
      return `/about-us`
    }
    // Backup for all other types
    return "/"
  },
}
