// @ts-check
import React, { createContext, useContext, useState } from "react"

const ModalStateContext = createContext({ isVisible: null, setIsVisible: null })

export const ModalStateProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <ModalStateContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </ModalStateContext.Provider>
  )
}

export const useModalStateValue = () => useContext(ModalStateContext)
