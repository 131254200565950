//@ts-check
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { withPreviewResolver } from "gatsby-source-prismic"
import { useMediaQuery } from "react-responsive"
import { Layout, Icon } from "antd"
import { CustomHeader } from "./CustomHeader"
import { FadeIn } from "./FadeInContainer"
import "./layout.css"
import { getTheme } from "../theme"
import { CustomMenu } from "./CustomMenu"
import Image from "./image"
import { Flex } from "./Flex"
import useOnClickOutside from "use-onclickoutside"
// import { RDVModal } from "./RDVModal"
import { ModalStateProvider } from "./RDVModal/context"
import { ButtonRDV } from "./buttonRDV"
import { ContactFormStateProvider } from "./ContactForm/context"
// import { ContactForm } from "./ContactForm"

import { CustomFooter } from "./CustomFooter"

const { linkResolver } = require("../utils/linkResolver")

const { Content, Sider } = Layout

export const useIsTabletOrMobile = () => {
  const isDesktop = useMediaQuery({ query: "(min-device-width: 1050px)" })
  return !isDesktop
}

export const useIsMobile = () => {
  const isDesktop = useMediaQuery({ query: "(min-device-width: 500px)" })
  return !isDesktop
}

export const getIsIos = () =>
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

const CustomLayout = ({ location, children }) => {
  const [isWaiting, setIsWaiting] = useState(true)
  const [isCollapsed, setIsCollpased] = useState(true)
  const [isMenuMobileDisplayed, setIsMenuMobileDisplayed] = useState(false)

  useEffect(() => {
    // First load, layout init issue in production
    setTimeout(() => setIsWaiting(false), 400)
  }, [])

  useEffect(() => {
    // First load, layout init issue in production
    setTimeout(() => setIsMenuMobileDisplayed(!isCollapsed), 100)
  }, [isCollapsed])

  const isTabletOrMobile = useIsTabletOrMobile()

  const ref1 = React.useRef(null)
  useOnClickOutside(ref1, () => setIsCollpased(true))

  const data = useStaticQuery(graphql`
    query MenuLinks {
      prismicMenu {
        data {
          image_header {
            url
            alt
          }
          image_header_mobile {
            url
            alt
          }
          menu_links {
            icon
            label {
              text
            }
            custom_path
            link {
              url
            }
          }
        }
      }
    }
  `)

  const imgData = data.prismicMenu.data.image_header
  const imgDataMobile = data.prismicMenu.data.image_header_mobile

  const menuLinks = data.prismicMenu.data.menu_links

  // TODO EMOTION
  const LAYOUT_STYLE = {
    color: getTheme().textColor,
    minHeight: "100vh",
    fontFamily: "Futura",
    backgroundColor: getTheme().primaryBackgroundColor,
  }

  if (isWaiting) {
    return <div />
  }

  return (
    <ModalStateProvider>
      <ContactFormStateProvider>
        {/* <RDVModal /> */}
        {/* <ContactForm /> */}
        {isTabletOrMobile ? (
          <Layout style={LAYOUT_STYLE}>
            <Sider
              width={window.innerWidth <= 400 ? "100%" : 400}
              trigger={null}
              collapsedWidth={0}
              collapsible
              collapsed={isCollapsed}
              style={{
                backgroundColor: getTheme().menuBackgroundColor,
              }}
            >
              <div ref={ref1}>
                <Flex
                  style={{
                    height: 130,
                    padding: 10,
                    backgroundColor: getTheme().primaryBackgroundColor,
                  }}
                >
                  <Image
                    style={{ width: "100%", maxWidth: "300px" }}
                    src={imgData.url}
                    alt={imgData.alt}
                  />
                </Flex>
                <FadeIn
                  // @ts-ignore
                  isVisible={isMenuMobileDisplayed}
                >
                  <CustomMenu
                    menuLinks={menuLinks}
                    location={location}
                    close={() => setIsCollpased(true)}
                    mode="vertical"
                  />
                  {!isCollapsed && (
                    <Flex
                      style={{
                        width: "100%",
                        marginTop: 40,
                        justifyContent: "center",
                      }}
                    >
                      <ButtonRDV
                        onPrimaryColor
                        cb={() => setIsCollpased(true)}
                      />
                    </Flex>
                  )}
                </FadeIn>
              </div>
            </Sider>
            <Layout>
              <CustomHeader
                // @ts-ignore
                trigger={
                  // TODO
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsCollpased(!isCollapsed)}
                  >
                    {isCollapsed && <Icon type="menu" />}
                  </div>
                }
                isCollapsed={isCollapsed}
                // TODO
                style={{ height: 100 }}
                imgData={imgDataMobile}
              />
              <Content style={{ margin: "0 0", minWidth: "80vw" }}>
                {children}
              </Content>
              <CustomFooter />
            </Layout>
          </Layout>
        ) : (
          <Layout style={LAYOUT_STYLE}>
            <CustomHeader
              imgData={imgData}
              // @ts-ignore
              extra={
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    fontSize: "14px",
                    marginTop: 5,
                  }}
                >
                  <CustomMenu menuLinks={menuLinks} location={location} />
                  <div style={{ marginLeft: 30 }}>
                    {/* TODO integrate to menu with button style ?  */}
                    <ButtonRDV />
                  </div>
                </div>
              }
            />
            <Content style={{ margin: "0 0" }}>{children}</Content>
            <CustomFooter />
          </Layout>
        )}
      </ContactFormStateProvider>
    </ModalStateProvider>
  )
}

export default CustomLayout

// export default withPreviewResolver(CustomLayout, {
//   repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
//   // @ts-ignore
//   linkResolver,
// })
