// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-guides-pratiques-js": () => import("./../../../src/pages/guides-pratiques.js" /* webpackChunkName: "component---src-pages-guides-pratiques-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tokill-js": () => import("./../../../src/pages/tokill.js" /* webpackChunkName: "component---src-pages-tokill-js" */),
  "component---src-templates-guide-jsx": () => import("./../../../src/templates/guide.jsx" /* webpackChunkName: "component---src-templates-guide-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-sub-page-jsx": () => import("./../../../src/templates/subPage.jsx" /* webpackChunkName: "component---src-templates-sub-page-jsx" */)
}

