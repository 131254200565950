/* Override also variables.css global var*/

export const getTheme = () => ({
  activeColor: "#368eb2",
  onButtonColor: "#fff",
  buttonPrimaryColor: "#3BAEC9",
  buttonSecondaryColor: "#34467A",
  buttonTernaryColor: "#34467A",
  textColor: "#34467A",
  titleColor: "#34467A",
  subTitleColor: "#3BAEC9",
  secondaryBackgroundColor: "#F8F8F8",
  primaryBackgroundColor: "#fff",
  primaryBackgroundColor07: "#f1f2f5",

  menuBackgroundColor: "#34467A",
})
