//@ts-check
import React from "react"
import GatsbyImg from "gatsby-image"

// import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = ({
  src = "",
  fluid = null,
  style = { width: '100px', borderRadius: '3px' },
  alt = "image",
  // withSpinner = false,
  Layer = "",
  ...otherProps
}) => {
  const ImgRendered = fluid ? GatsbyImg : "img"
  const Container = Layer ? "div" : React.Fragment
  const ContainerStyle = Container !== React.Fragment ? { position: "relative" } : undefined
  return (
    // @ts-ignore
    <Container {...(ContainerStyle ? { style: ContainerStyle } : {})}  >
      <ImgRendered
        src={src}
        fluid={fluid}
        alt={alt}
        style={style}
        {...otherProps}
      />
      {Layer && <Layer />} 
    </Container>
  )
}

export default Image
