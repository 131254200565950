//@ts-check
import React, { useMemo } from "react"
import { navigate } from "gatsby"
import { Menu, Icon } from "antd"
import path from "path"

import "./CustomMenu.css"
import { getTheme } from "../theme"
import { Text } from "./Text"
import { useIsTabletOrMobile } from "./CustomLayout"

export const CustomMenu = ({
  menuLinks,
  location,
  mode = "horizontal",
  close = () => { },
}) => {
  const isMobile = useIsTabletOrMobile()

  const _menuLinks = menuLinks.map(menuLink => ({
    ...menuLink,
    key: menuLink.custom_path ? path.resolve(menuLink.custom_path) : menuLink.link && path.resolve(menuLink.link.url)

  }))


  const selectedKeys = useMemo(() => {
    const _selectedKeys = []
    const keys = _menuLinks.map(({ key }) => key)

    if (location.pathname === '/' && keys.includes('/')) {
      _selectedKeys.push('/')
    } else {
      keys
        .filter(key => key !== '/')
        .forEach(key => {
          if (location && location.pathname.startsWith(key)) {
            _selectedKeys.push(key)
          }
        })
    }


    return _selectedKeys

  }, [location.pathname])


  const MENU_STYLE =
    mode === "horizontal"
      ? {
        display: "flex",
        justifyContent: "center",
      }
      : { backgroundColor: getTheme().menuBackgroundColor, padding: 20 }
  return (
    <Menu
      onClick={({ key }) => {
        navigate(path.resolve(`/${key}`))
        // @ts-ignore
        isMobile && close()
      }}
      selectedKeys={selectedKeys}
      // @ts-ignore
      mode={mode}
      style={{
        lineHeight: "64px",
        borderBottom: "unset",
        ...MENU_STYLE,
      }}
    >
      {_menuLinks.map(_menuLink => {
        return (
          //@ts-ignore
          <Menu.Item
            style={{
              borderStyle: "solid",
              fontFamily: "Signika",
              textTransform: "uppercase",
              display: isMobile ? "flex" : "inherit",
              alignItems: isMobile ? "center" : "inherit",
              borderWidth: isMobile ? 0 : "1px",
              margin: isMobile ? "5 10px" : "0 10px",
              borderColor: "white",
              lineHeight: "30px",
              borderRadius: "20px",
            }}
            key={_menuLink.key}
            url={_menuLink.key}
          >
            {_menuLink.icon && <Icon type={_menuLink.icon} />}
            <Text
              style={{
                color: isMobile
                  ? selectedKeys.includes(_menuLink.key)
                    ? getTheme().activeColor
                    : getTheme().onButtonColor
                  : getTheme().titleColor,
              }}
              strong
            >
              {_menuLink.label.text}
            </Text>
          </Menu.Item>
        )
      })}
    </Menu>
  )
}
