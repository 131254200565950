// @ts-check
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { ButtonPrimary } from "./ButtonPrimary"
// import { useModalStateValue } from "./RDVModal/context"
import { Flex } from "./Flex"
import { useIsTabletOrMobile } from "./CustomLayout"
import { ButtonSecondary } from "./ButtonSecondary"
// import { Menu, Dropdown } from "antd"
import { getTheme } from "../theme"

export const ButtonRDV = ({ cb = null, onPrimaryColor = false }) => {
  const isMobile = useIsTabletOrMobile()
  const { prismicMenu } = useStaticQuery(graphql`
    query ButtonRDVQuery {
      prismicMenu {
        data {
          button_text {
            text
          }
          button_url {
            url
          }
          button_text_2 {
            text
          }
          button_url_2 {
            url
          }
        }
      }
    }
  `)

  // const { setIsVisible } = useModalStateValue()

  // const menu = (
  //   <Menu>
  //     <Menu.Item>
  //       <a target="_blank" href={prismicMenu.data.button_url.url}>
  //         {prismicMenu.data.button_text.text}
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a target="_blank" href={prismicMenu.data.button_url_2.url}>
  //         {prismicMenu.data.button_text_2.text}
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // )

  return (
    <Flex
      style={{
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <ButtonSecondary
        style={{
          marginLeft: isMobile ? "0px" : "20px",
          marginTop: isMobile ? 14 : 0,
          ...(onPrimaryColor ? { borderColor: getTheme().onButtonColor } : {}),
        }}
        size={isMobile ? "small" : "default"}
        onClick={() => {
          window && window.open(prismicMenu.data.button_url.url)
        }}
        label={prismicMenu.data.button_text.text}
      />
      <ButtonPrimary
        style={{
          marginLeft: isMobile ? "0px" : "20px",
          marginTop: isMobile ? 14 : 0,
        }}
        size={isMobile ? "small" : "default"}
        onClick={() => {
          window && window.open(prismicMenu.data.button_url_2.url)
        }}
        label={prismicMenu.data.button_text_2.text}
      />
    </Flex>
  )
}
