// @ts-check
import React from "react"
import { Icon, Layout, Row } from "antd"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"
import { Button } from "antd"
import { navigate } from "gatsby"

// @ts-ignore
import Facebook from "../assets/facebook.svg"
// @ts-ignore
import Instagram from "../assets/instagram.svg"


const { Footer } = Layout

export const CustomFooter = ({ }) => {
  const data = useStaticQuery(graphql`
    query prismicFooterQuery {
      prismicFooter {
        data {
          mentions_legales_link {
            url
          }
          instagram_link {
            url
          }
          facebook_link {
            url
          }
        }
      }
    }
  `)

  const { mentions_legales_link, instagram_link, facebook_link } = data.prismicFooter.data

  return (
    <Footer style={{ textAlign: "center" }}>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button onClick={() => navigate(mentions_legales_link.url)} type="link">
          Mentions Légales
      </Button>
        <Row style={{ marginLeft: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Icon
            style={{ fontSize: 22 }}
            component={Facebook}
            onClick={() => window && window.open(facebook_link.url)}
          />
          <Icon
            style={{ marginLeft: 8, fontSize: 22 }}
            onClick={() => window && window.open(instagram_link.url)}
            component={Instagram}
          />
        </Row>
      </Row>
    </Footer>
  )
}
