//@ts-check
import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

export const FadeIn = styled.div`
  opacity: ${props =>
    // @ts-ignore
    props.isVisible ? 1 : 0};
  transition: opacity 0.2s; //X
`

export const FadeInContainer = ({ children, location }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [derivatedChildren, setDerivatedChildren] = useState(children)

  useEffect(() => {
    // Delay first render - production mode too fast - layout and video badly initialized
    if (!isVisible) {
      setTimeout(() => setIsVisible(true), 200) //X fadein when mount
    }
    return () => {
      setTimeout(() => setIsVisible(false)) //fadein when mount
    }
  }, [isVisible]) //fadeout when unmount

  useEffect(() => {
    setIsVisible(false)
  }, [location.pathname]) //fadeout when change location

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => setDerivatedChildren(children), 150) // X - Because Hook are only post render effect, pass children to state to control render
      setTimeout(() => setIsVisible(true), 300) //2X fadein
    }
  }, [isVisible, children])

  // @ts-ignore
  return <FadeIn isVisible={isVisible}>{derivatedChildren}</FadeIn>
}
