// @ts-check
import React from "react"
import { Layout, Alert } from "antd"
import Image from "./image"
import { Flex } from "./Flex"
// import { useMainColorFromImg } from "../hooks/useMainColorFromImg"
import { useIsTabletOrMobile } from "./CustomLayout"
import { FadeIn } from "./FadeInContainer"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"

const { Header } = Layout

export const CustomHeader = ({
  imgData,
  trigger = "",
  extra = "",
  isCollapsed = true,
  style = {},
}) => {
  const data = useStaticQuery(graphql`
    query headerQuery {
      prismicHomepage {
        data {
          banner_title {
            text
          }
          banner_text {
            html
          }
          banner_type {
            text
          }
        }
      }
    }
  `)
  const { banner_type, banner_text, banner_title } = data.prismicHomepage.data

  // const color = useMainColorFromImg(imgData.url)
  const isMobile = useIsTabletOrMobile()
  return (
    <>
      {/* TO DO Clean this shitlater */}
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .ant-alert-description {font-size: 16px }
    `,
        }}
      />
      <Header
        style={{
          background: "#fff",
          display: "flex",
          alignItems: "center",
          height: isMobile ? 120 : 155,
          padding: 0,
          width: "100%",
          ...style,
        }}
      >
        <Flex
          style={{
            margin: "0px 0px 0px 30px",
            justifyContent: "space-between",
            flexDirection: isMobile ? "row" : "column",
            width: "100%",
          }}
        >
          {!!trigger && trigger}

          <FadeIn
            // @ts-ignore
            isVisible={!isMobile || isCollapsed}
          >
            <div style={isMobile ? { width: "200px", marginRight: 20 } : {}}>
              <Image
                style={
                  isMobile
                    ? { width: "100%", height: "100%" }
                    : { width: 330, height: "100%" }
                }
                src={imgData.url}
                alt={imgData.alt}
              />
            </div>
          </FadeIn>

          {!!extra && extra}
        </Flex>
      </Header>
      {banner_type && banner_type.text && (
        <Alert
          message={banner_title.text}
          style={{ fontSize: 16 }}
          description={
            <div dangerouslySetInnerHTML={{ __html: banner_text.html }}></div>
          }
          type={banner_type.text}
          showIcon
          closable
          onClose={() => null}
        />
      )}
    </>
  )
}
