//@ts-check
import React from "react"

export const Flex = ({ children, style = {}, ...otherProps }) => (
  <div
    {...otherProps}
    style={{ display: "flex", alignItems: "center", ...style }}
  >
    {children}
  </div>
)
