// @ts-check
import React, { createContext, useContext, useState } from "react"

const ContactFormStateContext = createContext({
  isVisible: null,
  setIsVisible: null,
})

export const ContactFormStateProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <ContactFormStateContext.Provider value={{ isVisible, setIsVisible }}>
      {children}
    </ContactFormStateContext.Provider>
  )
}

export const useContactFormStateValue = () =>
  useContext(ContactFormStateContext)
